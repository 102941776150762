/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (front.base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './global/app.css';
import './global/materialize_styles.css';
import './front/front.css';
import './front/tabs.css';
import './front/product-card.css';
import './front/forms.css';
import './front/product-page.css';
import './front/category.css';
import './front/front-search.css';
import './front/breadcrumb.css';
import './front/menu.css';
import './front/header.css';

// start the Stimulus application
// import './bootstrap';

// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;


// import './scripts/jquery.js';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize.min.js';

import './front/materialize_init.js';



